import { mutateEquipment } from "@/api/equipment/useEquipmentQuery";
import { EquipmentCreateForm } from "@/features/equipment/components/form/EquipmentCreateForm";
import { EquipmentDetailForm } from "@/features/equipment/components/form/EquipmentDetailForm";
import { EquipmentUpdateForm } from "@/features/equipment/components/form/EquipmentUpdateForm";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { DowntimeReasonsDetailForm } from "@/features/downtimeReasons/form/DetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { SiteDetailForm } from "@/features/site/components/form/SiteDetailForm";
import { useStandardLayout } from "@/features/standardLayout/Context";
import { Filter } from "@/features/standardLayout/Filter";
import { LinkAnchor } from "@/features/standardLayout/LinkAnchor";
import { ListTable } from "@/features/standardLayout/ListTable";
import { PaginationBox } from "@/features/standardLayout/PaginationBox";
import { SearchBox } from "@/features/standardLayout/SearchBox";
import { Sorter } from "@/features/standardLayout/Sorter";
import { ActionHeader } from "@/features/standardLayout/StandardInfoActionHeader";
import { flexGrowable, flexStable, StandardLayoutTableWrapper } from "@/features/standardLayout/Styles";
import customAlert from "@/features/ui/alert/alert";
import { ConfirmForm } from "@/features/ui/confirm";
import { handleErrorResponse } from "@/utils/errorMessage";
import { css } from "@emotion/css";
import { ActionIcon, Avatar, Flex, Text, Tooltip } from "@mantine/core";
import { AuthSignupPost201ResponseEquipment, EquipmentsGet200Response, MasterApiEquipmentsEquipmentIdDeleteRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconFilterPlus, IconTrash } from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { useMemo } from "react";
import { Column, SelectColumn } from "react-data-grid";

export const Equipment = () => {
  const { openModal, closeModal } = useModal();
  const { selectedRows, refetch } = useStandardLayout();
  const { mutate: deleteMutate } = useMutation(
    (params: MasterApiEquipmentsEquipmentIdDeleteRequest) =>
      mutateEquipment.delete(params).mutationFn(params as MasterApiEquipmentsEquipmentIdDeleteRequest | any),
    {
      onSuccess: () => {
        customAlert(
          "설비 삭제에 성공하였습니다.",
          "삭제 성공",
          "green"
        );
        refetch();
      },
      onError: (error) => {
        customAlert(handleErrorResponse({ error, errorContext: "설비 코드" }), "설비 삭제 실패", "red");
      }
    }
  );

  const columns: readonly Column<AuthSignupPost201ResponseEquipment>[] = useMemo(() => [
    {
      ...SelectColumn,
      resizable: true,
    },
    {
      key: "siteCode",
      name: "사업장코드",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor onClick={() => openModal(<SiteDetailForm siteCode={formatterProps.row.siteCode} />, null, "")}>{formatterProps.row.siteCode}</LinkAnchor>
        )
      },
    },
    {
      key: "code",
      name: "설비코드",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor onClick={() => openModal(<EquipmentDetailForm equipmentCode={formatterProps.row.code} />, null, "")}>{formatterProps.row.code}</LinkAnchor>
        )
      },
    },
    {
      key: "name",
      name: "설비명",
      sortable: true,
      resizable: true,
    },
    {
      key: "equipmentType",
      name: "설비유형",
      sortable: true,
      resizable: true,
    },
    {
      key: "volt",
      name: "전압",
      sortable: true,
      resizable: true,
      cellClass: css`
        justify-content: flex-end;
      `,
    },
    {
      key: "fromLocationName",
      name: "원부자재 로케이션",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <LocationDetailForm locationCode={formatterProps.row.fromLocationCode} />,
                null,
                ""
              )
            }
          >
            {formatterProps.row.fromLocationName}
          </LinkAnchor>
        )
      },
    },
    {
      key: "toLocationName",
      name: "입고로케이션",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <LocationDetailForm locationCode={formatterProps.row.toLocationCode} />,
                null,
                ""
              )
            }
          >
            {formatterProps.row.toLocationName}
          </LinkAnchor>
        )
      },
    },
    {
      key: "targetLocationName",
      name: "품목 생산 로케이션",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <LinkAnchor
            onClick={() =>
              openModal(
                <LocationDetailForm locationCode={formatterProps.row.targetLocationCode} />, null, ""
              )
            }
          >
            {formatterProps.row.targetLocationName}
          </LinkAnchor>
        )
      },
    },
    {
      key: "seq",
      name: "시퀀스(순서)",
      sortable: true,
      resizable: true,
      cellClass: css`
        justify-content: flex-end;
      `,
    },
    {
      key: "downtimeReasonCode",
      name: "비가동사유코드",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        const downtimeReasonCodes = Array.isArray(formatterProps.row.downtimeReasonCode)
          ? formatterProps.row.downtimeReasonCode
          : [formatterProps.row.downtimeReasonCode];

        return (
          <Flex gap="xs" align="center" h="100%">
            {downtimeReasonCodes.map((code, index) => (
                <LinkAnchor
                key={index}
                  onClick={() =>
                    openModal(
                      <DowntimeReasonsDetailForm downtimeReasonCode={code} />, null, ""
                    )
                  }
                >
                  {code}
                </LinkAnchor>
            ))}
          </Flex>
        );
      },
    },
    {
      key: "options",
      name: "액션",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        const updateEquipmentActionView = () => {
          openModal(
            <EquipmentUpdateForm formatterProps={formatterProps?.row} />,
            null,
            "설비 수정",
            true
          );
        };

        const deleteEquipmentAction = () => {
          openModal(
            <ConfirmForm
              message="선택한 행을 삭제하시겠습니까?"
              yesCallback={() => deleteMutate({ equipmentId: formatterProps?.row.id })}
              noCallback={() => { }}
              commonCallback={() => closeModal(null)}
            />,
            null,
            "설비 삭제",
          )
        }

        return (
          <Flex w="100%" h="100%" justify="center" align="center" gap="md">
            <ActionIcon
              variant="subtle"
              onClick={updateEquipmentActionView}
              color="yellow"
            >
              <IconEdit />
            </ActionIcon>
            <ActionIcon
              variant="subtle"
              onClick={() => deleteEquipmentAction()}
              color="red"
            >
              <IconTrash />
            </ActionIcon>
          </Flex>
        );
      },
    },
  ], [deleteMutate, openModal, closeModal]);

  const handleDelete = () => {
    if ([...selectedRows].length === 0) {
      customAlert("삭제할 설비를 선택해주세요.", "삭제 실패", "red");
      return;
    }

    openModal(
      <ConfirmForm
        message="선택한 행을 삭제하시겠습니까?"
        yesCallback={() => {
          [...selectedRows].forEach((row) => {
            deleteMutate({ equipmentId: row });
          });

          selectedRows.clear();
        }
        }
        noCallback={() => { }}
        commonCallback={() => closeModal(null)}
      />,
      null,
      "설비 삭제",
    )
  }

  return (
    <Flex w="100%" h="100%" gap="xs" direction="column">
      <Flex p="sm" justify="space-between" rowGap="md">
        <ActionHeader createFn={() => openModal(<EquipmentCreateForm />, null, "설비 추가", true)} createDataTitle="설비" deleteFn={() => handleDelete()} />
      </Flex>
      <Flex bg="white" px="sm" direction="column" gap="xs">
        <Text fz="xl" fw={600}>설비 리스트</Text>
        <Text fz="xs">우리 회사에서 다루는 설비에 대해 조회합니다.</Text>
      </Flex>
      <Flex justify="space-between" align="flex-start" px="sm">
        <Flex wrap="wrap" styles={flexGrowable}>
          <Flex gap="xs" align="center" h="100%" wrap="wrap" direction="row">
            <Tooltip label={"사용자 정의 필터입니다. \"필터 추가\" 또는 \"정렬 추가\" 버튼을 클릭하여 조건을 입력합니다."}>
              <Avatar color="blue" variant="outline">
                <IconFilterPlus />
              </Avatar>
            </Tooltip>
            <Flex direction="column" gap="xs" wrap="wrap">
              <Filter />
              <Sorter />
            </Flex>
          </Flex>
        </Flex>
        <Flex styles={flexStable}>
          <SearchBox />
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" p="sm" justify="space-between" gap="md" direction="column">
        <StandardLayoutTableWrapper>
          <ListTable<EquipmentsGet200Response, AuthSignupPost201ResponseEquipment> columns={columns} />
        </StandardLayoutTableWrapper>
        <PaginationBox<EquipmentsGet200Response> />
      </Flex>
    </Flex>
  );
};