import { equipments } from "@/api/equipment/useEquipmentQuery";
import { MonitoringBox } from "@/features/monitoring/components/parentsBox/Monitoring";
import { Parents } from "@/features/monitoring/components/parentsBox/Parents";
import { Main } from "@/features/standard/Main";
import { BASE_URL } from "@/instance/axios";
import { getLowerFields, getTextFields, getUpperFields } from "@/utils/monitoringFieldUtils";
import { MultiSelect } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useSocket } from "socket.io-react-hook";


export type MonitoringDataProps = {
    denominatorLoad?: number
    equipmentCode?: string
    equipmentName?: string
    errorName?: string
    errorNumber?: number
    idleTime?: string
    idleTimeCreatedAt?: Date
    influxEquipmentCode?: string
    itemCode?: string
    itemName?: string
    itemSpec?: string
    keyCamStatus?: string
    keyCamStatusNumber?: number
    limitCounter?: number
    loadRatio?: number | undefined
    moldCavity?: number | undefined
    moldCode?: string
    msPerUnit?: number
    numeratorLoad?: number
    presetCounter?: number
    pressStatus?: string
    pressStatusNumber?: number | undefined
    remainTime?: string
    runTime?: string
    runTimeCreatedAt?: Date
    runTimeRatio?: number
    spm?: number
    totalCounter?: number
    totalTon?: number
    worker?: string
    equipmentType?: 'FORMING' | 'PACKING' | 'FORGING';
    mainFeederRpm?: number;
    mainScrewRpm?: number;
    sideFeederRpm?: number;
    sideScrewRpm?: number;
    vacuumRpm?: number;
    vacuumPressure?: number;
    resinTemperature?: number;
    resinPressure?: number;
    packingCounter?: number;
    lotId?: number;
    quantity?: string;
    targetQuantity?: string;
    kgPerCount?: string;
    totalPackingCounter?: string;
    workingTime?: string;
}

const Monitoring = Object.assign({}, Main, { Parents });

const useSocketListeners = (
    equipmentCodes: string[] | undefined,
    socket: any,
    setMonitoringData: React.Dispatch<React.SetStateAction<MonitoringDataProps[]>>
) => {
    useEffect(() => {
        if (!equipmentCodes) return;

        equipmentCodes.forEach((code, index) => {
            const roomName = `PM_${code}`;
            socket.on(roomName, (message: MonitoringDataProps) => {
                setMonitoringData((prevData) => {
                    const newData = [...prevData];
                    newData[index] = message;
                    return newData;
                });
            });
            socket.emit("call", "socket.join", { room: roomName }, (err: any) => {
                if (err) console.error(err);
            });
        });

        return () => {
            equipmentCodes.forEach((code) => {
                const roomName = `PM_${code}`;
                socket.off(roomName);
            });
        };
    }, [socket, equipmentCodes, setMonitoringData]);
};

export const MonitoringTable = () => {

    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [multiSelectData, setMultiSelectData] = useState<{ value: string; label: string; }[]>([]);
    const [monitoringData, setMonitoringData] = useState<MonitoringDataProps[]>([]);
    const { socket } = useSocket(BASE_URL, { autoConnect: true });

    const { data: equipmentData } = useQuery({
        ...equipments?.find({
            query: {
                $and: [
                    {
                        influxEquipmentCode: {
                            $and: [
                                { $not: null },
                                { $not: '' },
                                { $or: selectedOptions ? selectedOptions.map(option => ({ $eq: option })) : [] }
                            ],
                        },
                    },
                ],
            },
            sort: "seq, id",
            populate: ['location', "works"],
        })
    });

    const equipmentCodes = equipmentData?.data?.map((data) => data?.code);

    useSocketListeners(equipmentCodes, socket, setMonitoringData);

    useEffect(() => {
        if (multiSelectData.length === 0 && equipmentData) {
            setMultiSelectData(
                equipmentData?.data?.map((data) => ({
                    value: data.influxEquipmentCode as string,
                    label: data.name,
                })) || []
            );
        }
    }, [equipmentData]);

    const handleMultiSelectChange = (values: string[]) => {
        setSelectedOptions(values);
        setMonitoringData([]);
    };



    return (
        <>
            <MultiSelect
                data={multiSelectData ?? []}
                placeholder="검색할 기계를 선택해주세요."
                onChange={handleMultiSelectChange}
            />
            <Monitoring.Parents>
                {monitoringData.map((data, index) => (
                    <MonitoringBox
                        key={index}
                        statusInfo={{ pressStatus: data?.pressStatus, errorName: data?.errorName }}
                        textFields={getTextFields(data)}
                        upperFields={getUpperFields(data)}
                        lowerFields={getLowerFields(data)}
                    />
                ))}
            </Monitoring.Parents>
        </>
    );


};

